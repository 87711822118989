import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import TableComponent from './TableComponent';
import { getOrderList, getOrderHistoryList } from 'actions/buyer';
import helper from 'constants/helperFunction';
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import EnhancedTableToolbarOpen from './EnhancedTableToolbarOpen'
import CircularProgress from '@mui/material/CircularProgress';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';

const styles = {
  root: {
    width: '100%',
    marginTop: 0,
  },
  paper: {
    width: '100%',
    marginBottom: 2,
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  list: {
    maxWidth: 239
  },
  fontSize: {
    fontSize: '1rem'
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const Order = (props) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [month, setMonth] = useState(moment().format("MMMM"))
  const [filterObj, setFilterObj] = useState('')
  let rows = []

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    let data = {
      month: month === moment().format("MMMM") ? '' : month,
      year: moment().format("GGGG"),
      filter: '',
      page: newPage + 1,
      perPage: 50,
    }
    props.getOrderHistoryList({ data })
      ;
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  };

  const filterDataHistory = (data) => {
    setFilterObj(data);
    setPage(data.page)
    setMonth(data.month)
  }

  const filterData = (data) => {
    setFilterObj(data);
  }

  const statusStyle = (status) => {
    return status.includes("Processed") ? "text-white bg-primary" : status.includes("pending") ? 'text-white bg-dark' : status.includes("Ready For Dispatch") ? "text-white bg-amber" : status.includes("Placed") ? "text-white bg-yellow" : status.includes("Cancelled") ? "text-white bg-grey" : status.includes("Delivered") ? "text-white bg-green" : status.includes("Requested") ? "text-white bg-info" : status.includes("New") ? "text-white bg-danger" : status.includes("LOST") ? "text-white bg-black" : status.includes("RTO after Delivery") ? "text-white bg-brown" : status.includes("RTO") ? "text-white bg-cyan" : "text-white bg-info";
  }

  const { identifier, orderFromParent } = props;
  let count = orderFromParent && orderFromParent.detail && orderFromParent.detail[0] && orderFromParent.detail[0] && orderFromParent.detail[0].metadata && orderFromParent.detail[0].metadata[0] && orderFromParent.detail[0].metadata[0].total

  orderFromParent && orderFromParent.detail && orderFromParent.detail[0] && orderFromParent.detail[0] && orderFromParent.detail[0].data.length > 0 && orderFromParent.detail[0].data.map((dataOne, index) => {
    rows.push({
      orderId: dataOne.order_id,
      orderType: <div key={'recent1'} className={'badge text-uppercase text-white'} style={{ backgroundColor: `${helper.getProdColor(dataOne.orderType)}` }}>{dataOne.orderType ? dataOne.orderType : 'N/A'}</div>,
      seller: <NavLink className="text-reset text-primary text-capitalize" to={`/view-seller/${dataOne.seller_id && dataOne.seller_id._id}`}>
        {dataOne.seller_id ? dataOne.seller_id.company_name : ''}
      </NavLink>,
      status:
        <div key={'recent'} className={` badge text-uppercase ${statusStyle(dataOne.requested !== "Requested" ? dataOne.order_status[dataOne.order_status.length - 1].status : 'Requested')}`}>
          {dataOne.requested === "Requested" ?
            dataOne.requested
            :
            dataOne.order_status[dataOne.order_status.length - 1].status === 'New' ? 'Placed' : dataOne.order_status[dataOne.order_status.length - 1].status}
        </div>,
      amount: <span>&#8377;{(Number(dataOne.total_amount)).toFixed(2)}</span>,
      delivery_charges: dataOne.delivery_charges,
      product: 'assets/productImage/user.jpg',
      description: dataOne.paymentType,
      value: dataOne.breadth,
      date: dataOne.createdAt,
      tableAmout: <span>&#8377;{Number(dataOne.delivery_charges) > 0 ? (Number(dataOne.delivery_charges) + Number(dataOne.total_amount)).toFixed(2) : (Number(dataOne.total_amount)).toFixed(2)}</span>
    })
  })

  let orderData = orderFromParent ? orderFromParent.detail ? orderFromParent.detail.length > 0 ? orderFromParent.detail[0].data : [] : [] : []

  return (
    <div style={styles.root}>
      <Paper sx={styles.paper}>
        {identifier === "orderHistoryList" ?
          <EnhancedTableToolbar identifier={identifier} filterData={filterDataHistory} show={props.show} page={page} />
          :
          identifier === "orderList" ?
            <EnhancedTableToolbarOpen identifier={identifier} filterData={filterData} show={props.show} />
            :
            null
        }
        {
          props.loading ?
            <div className="loader-view">
              <CircularProgress />
            </div>
            :
            <div style={styles.tableWrapper}>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size='medium'
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  styles={styles}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {rows.length < 1 ? <TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}>{identifier === "orderList" ? `You don't have open orders !` : identifier === "orderHistoryList" ? `You don't have orders !` : 'Sorry no records found'}</TableCell></TableRow> :
                    stableSort(rows, getSorting(order, orderBy))
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableComponent
                            row={row}
                            labelId={labelId}
                            value={props.value}
                            itemListFromParent={orderData}
                            show={props.show}
                            filterObj={filterObj}
                          />
                        )
                      })}
                </TableBody>
              </Table>
            </div>
        }
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count ? count : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { loading } = buyer;
  return { loading }
};

export default connect(mapStateToProps, { getOrderHistoryList, getOrderList })(Order)