import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOrder from './editOrderDetail';
import { connect } from 'react-redux';
import { getOrderDetails } from 'actions/order'
import moment from 'moment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import AppConfig from 'constants/config'
import { useParams } from 'react-router-dom';

const OrderDetailTable = (props) => {
    const params = useParams()
    const [orderProductData, setOrderProductData] = useState([])
    const [status, setStatus] = useState('')
    const [cancel, setCancel] = useState(false)
    const [removedProd, setRemovedProd] = useState([])
    const [orderMedicineType, setOrderMedicineType] = useState('')
    const [dissabledButtton, setDissabledButtton] = useState(false)

    const handleClick = async (e, value, ptr, quantity, index) => {
        if (quantity !== '' && ptr !== '') {
            let tempOrderProduct = [...orderProductData];
            tempOrderProduct[index].paid = ptr;
            tempOrderProduct[index].quantity = quantity;
            setOrderProductData(tempOrderProduct)
            setRemovedProd([])
            props.onClickHandle(e, value, tempOrderProduct);
            if (orderProductData.length > 0 && props.orderDetails.products !== tempOrderProduct && !cancel) {
                let finalGrand = 0;
                orderProductData.map((eachProduct) => {
                    return (
                        finalGrand = finalGrand + Number(getFinalValueWithoutGst(eachProduct))
                    )
                })
                orderProductData.map((eachProduct) => {
                    props.orderDetails.products.filter(async (e) => {
                        if (e._id === eachProduct._id && e.quantity !== eachProduct.quantity) {
                            setRemovedProd([...removedProd].concat(eachProduct))
                        }
                    })
                })
                if (finalGrand < orderMedicineType.chgReqAmount) {
                    setCancel(true)
                }
            }
        } else {
            props.onClickHandle(e, value, '');
        }
    }
    const ShortExpArr = orderProductData.filter( (data) => data.expiry_status === 'Short Expiry')
    const getMediCategory = async () => {
        let { products } = props.orderDetails ? props.orderDetails : []
        setOrderProductData(JSON.parse(JSON.stringify(products)))
        setStatus(props.orderDetails.requested);
        await axios({
            method: 'get',
            url: `${AppConfig.baseUrl}medicine/listMediCategory`,
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem('token')
            }
        }).then(async (result) => {
            if (result.data.error) {
            } else {
                let mediTypeData = result.data.data.filter(e => e.name.toLowerCase() === props.orderDetails.orderType.toLowerCase());
                setOrderMedicineType(mediTypeData[0])
            }
        }).catch(error => {
            console.log("error catch", error)
        });
    }

    useEffect(() => {
        getMediCategory()
    }, [])

    useEffect(() => {
        let { products } = props.orderDetails ? props.orderDetails : []
        setOrderProductData(JSON.parse(JSON.stringify(products)))
        setStatus(props.orderDetails.requested);
    }, [props.orderDetails])

    const getFinalValueWithoutGst = (data) => {
        let tempValue = data.inventory_id && data.inventory_id.discount_id && (data.inventory_id.discount_id.name === 'Same' || data.inventory_id.discount_id.name === 'SameAndDiscount') ?
            (((data.quantity / data.inventory_id.discount_id.discount_on_product.purchase) * data.inventory_id.discount_id.discount_on_product.bonus) + Number(data.quantity)) * (data.ePTR ? data.ePTR : data.PTR)
            : (data.ePTR ? data.ePTR : data.PTR) * data.quantity
        let finalValue = tempValue;
        return finalValue
    }

    const button = (title, qty, index, cost, purchase) => {
        if (title !== undefined && qty !== undefined && index !== undefined && cost !== undefined) {
            return (
                < div className={'action'} >
                    <EditOrder handleClick={handleClick} title={title} status={status} index={index} qty={parseInt(qty)} cost={cost} purchase={purchase} />
                </div >
            )
        }
    }

    const getFinalValue = (data) => {
        let tempValue = data.discount_name && (data.discount_name === 'Same' || data.discount_name === 'SameAndDiscount') ?
            (((data.quantity / data.discount_on_product.purchase) * data.discount_on_product.bonus) + Number(data.quantity)) * (data.ePTR ? data.ePTR : data.PTR)
            : (data.ePTR ? data.ePTR : data.PTR) * data.quantity
        let finalValue = ((Number(data.GST) / 100) * tempValue) + tempValue;
        return finalValue
    }

    const handleClose = (e) => {
        setCancel(false)
        props.getOrderDetails({ data: { orderId: params.id } })
    }

    const handleCancelOrder = async (e) => {
        setDissabledButtton(true)
        let prod = []
        for (const data of removedProd) {
            let inven = await props.orderDetails.products.filter(e => e._id === data._id);
            prod.push({
                productName: data.productName,
                product_id: data.inventory_id.product_id._id,
                inventory_id: data.inventory_id._id,
                quantity: inven[0].quantity
            })
        }
        let data = {
            order_cancel_reason: `Order value below Rs.${orderMedicineType.chgReqAmount}`,
            status: 'Cancelled',
            orderId: params.id,
            products: prod
        }
        await axios.post('/order/proccessCancelOrder', data, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        }).then(result => {
            if (result.data.error) {
                NotificationManager.error(result.data.title)
            } else {
                setCancel(false)
                NotificationManager.success(result.data.title);
                props.getOrderDetails({ data: { orderId: params.id } })
            }
        }).catch(error => {
            NotificationManager.error('Something went wrong, Please try again')
        });
    }

    return (
        <React.Fragment>
            <Paper className={'tableRoot'}>
                {orderProductData && orderProductData.length > 0 ?
                    <Table className={'tableMain'} aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Title</TableCell>
                                <TableCell align="left">Packing</TableCell>
                                <TableCell align="left">Expiry</TableCell>
                                <TableCell align="left">Offer</TableCell>
                                <TableCell align="left">MRP</TableCell>
                                <TableCell align="left">PTR</TableCell>
                                <TableCell align="left">Qty</TableCell>
                                <TableCell align="left">Effective PTR</TableCell>
                                <TableCell align="left">GST</TableCell>
                                <TableCell align="left">Final Price</TableCell>
                                {
                                    status === "New" ? <TableCell align="left">Action</TableCell> : ''
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderProductData.map((eachProduct, index) => (
                                <TableRow key={eachProduct.title + '' + index}>
                                    <TableCell align="left">{eachProduct.name} <br />
                                        {eachProduct.surCharge && eachProduct.surCharge > 0 ? <span className='text-white bg-danger' style={{ padding: "1px 5px" }}>Surcharge ({eachProduct.surCharge}%)</span> : ''}
                                    </TableCell>
                                    <TableCell align="left">{eachProduct.inventory_id.product_id.Type.name}</TableCell>
                                    <TableCell align="left">{moment(eachProduct && eachProduct.expiry_date).format('MM/YYYY')}</TableCell>
                                    <TableCell align="left">
                                        {
                                            eachProduct.discount_name && eachProduct.discount_name === 'SameAndDiscount' ?
                                                eachProduct.discount_on_product.purchase + '+' + eachProduct.discount_on_product.bonus + ' (Bonus) and ' + eachProduct.discount_per + '% Off' :
                                                eachProduct.discount_name && eachProduct.discount_name === 'Same' ?
                                                    eachProduct.discount_on_product.purchase + '+' + eachProduct.discount_on_product.bonus + ' (Bonus)' :
                                                    eachProduct.discount_name && eachProduct.discount_name === 'Different' ?
                                                        eachProduct.discount_on_product.purchase + '+' + eachProduct.discount_on_product.bonus + ' ' + eachProduct.discount_on_product.name :
                                                        eachProduct.discount_name && eachProduct.discount_name === 'DifferentAndDiscount' ?
                                                            eachProduct.discount_on_product.purchase + '+' + eachProduct.discount_on_product.bonus + ' ' + eachProduct.discount_on_product.name + ' and ' + eachProduct.discount_per + '% Off' :
                                                            eachProduct.discount_per + '% discount'
                                        }
                                    </TableCell>
                                    <TableCell align="left">&#x20B9;{(Number(eachProduct.MRP)).toFixed(2)}</TableCell>
                                    <TableCell align="left">&#x20B9;{(Number(eachProduct.PTR)).toFixed(2)}</TableCell>
                                    <TableCell align="left">{eachProduct.quantity}</TableCell>
                                    <TableCell align="left">{(eachProduct.ePTR ? eachProduct.ePTR : eachProduct.PTR).toFixed(2)}</TableCell>
                                    <TableCell align="left">{eachProduct.inventory_id.product_id.GST.value + "%"}</TableCell>
                                    <TableCell align="left">{getFinalValue(eachProduct).toFixed(2)}</TableCell>
                                    {
                                        status === "New" ?
                                            <TableCell align="left">
                                                {button(eachProduct.name, eachProduct.quantity, index, eachProduct.PTR, eachProduct && eachProduct.discount_on_product
                                                    && eachProduct.discount_on_product.purchase)}
                                            </TableCell>
                                            :
                                            ''
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    : 'No Items Found'}
            </Paper>
            { (ShortExpArr.length > 0 ) && <Paper className={'tableRoot'} style={{paddingTop: '1rem', paddingLeft: '1rem'}}>
                    { ShortExpArr.length > 0 && <h5 style={{color: "#072791"}}>Expiry date cannot be reduced while supply.</h5> }
            </Paper> }
            {/* -------------------------Cancel Order < 8000/2000 ------------------------------- */}
            <Dialog open={cancel} onClose={handleClose} fullWidth={true}
                maxWidth={'sm'}>
                <DialogTitle className="pb-0">
                    Cancel Order
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Order amount is below Rs.{orderMedicineType.chgReqAmount} (without GST), it must be greater than Rs.{orderMedicineType.chgReqAmount}, Would you like to cancel the order ?&nbsp;
                        {props.cancelText && props.cancelText}
                    </DialogContentText>
                    {
                        removedProd && removedProd.map((data, index) => {
                            return <h4 className='text-muted'> {++index}. {data.productName} </h4>
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleClose(e)} color='secondary' >
                        No
                    </Button>
                    <Button color='primary' onClick={(e) => handleCancelOrder(e)} disabled={dissabledButtton} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

const mapStateToProps = ({ order }) => {
    const { orderDetails } = order;
    return { orderDetails }
};

export default connect(mapStateToProps, { getOrderDetails })(OrderDetailTable);
